.phn-data-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  height: 80%;
}

.phn-data-table tbody {
  display: block;
  overflow-y: auto;
  height: calc(100% - 40px);
  width: 100%;
}

.phn-data-table thead,
.phn-data-table-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 40px;
}

.phn-data-table-row {
  cursor: pointer;
}

.phn-data-table td,
.phn-data-table th {
  padding: 10px;
  font-size: 0.8rem;
}

.phn-data-table-index {
  width: 30px;
  text-align: center;
}

.phn-data-table-header {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
}

.phn-data-table-row:hover {
  background-color: #f1f1f1;
}

.phn-data-table-row.selected {
  background-color: var(--highlight-main);
}

.phn-data-table-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.phn-data-table-pagination button {
  background-color: var(--primary-main);
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 30px;
}

.phn-data-table-pagination button:disabled {
  background-color: var(--gray-1);
  cursor: not-allowed;
}

.phn-data-table-pagination span {
  font-size: 14px;
}

@media (max-width: 768px) {
  .phn-data-table td,
  .phn-data-table th {
    padding: 5px;
    font-size: 0.6rem;
  }
}
