.create-story-selections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  width: 100%;
  height: 50px;
}

.create-story-selections-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 150px;
  height: 100%;
  border-radius: 10px;
  background-color: var(--gray-1);
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.create-story-selections-item.active {
  background-color: var(--highlight-main);
}

.create-story-selections-item:hover,
.create-story-selections-item.active:hover {
  transform: translateY(-3px); /* Slight upward movement on hover */
}

.create-story-selections-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.vocab-requirement-message {
  color: #666;
  font-size: 0.7em;
  margin-top: 5px;
}
