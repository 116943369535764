.phn-card-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 8px;
}

.phn-card-type-chip {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.phn-card-type-chip:hover {
  background-color: #e0e0e0;
}

.phn-card-type-chip.selected {
  background-color: var(--primary-main);
  color: white;
}

.phn-check-icon {
  margin-left: 8px;
  font-size: 14px;
  color: white;
}
