.decks-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust as needed */
}

.decks-deck-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 80%;
  padding-top: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.decks-deck-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
}

.decks-deck-item:hover {
  transform: translateY(-3px);
}

.decks-item-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.decks-deck-name {
  font-size: 1.2rem;
  margin: 0;
}

.delete-deck-popup {
  width: 300px;
}
