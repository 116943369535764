/* src/pages/LoginPage.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Light grey background */
}

.login-title {
  font-size: 1.8rem;
  font-weight: normal;
  color: var(--primary-main); /* Maroon color for the title */
  margin-bottom: 20px;
  text-align: center; /* Center the title text */
}

.login-form {
  width: 100%;
  max-width: 400px; /* Limit the max width of the form for better alignment */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #fff; /* White background for the form */
  border-radius: 10px; /* Slightly rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for the form */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.login-input-group {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the input group horizontally */
  margin-bottom: 15px;
}

.login-input-field {
  width: 100%; /* Full width of the container */
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.login-submit-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  width: 100%;
  color: #fff;
  background-color: var(--primary-main); /* Maroon background for button */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center; /* Center the text in the button */
}

.login-submit-button:hover {
  background-color: var(--primary-light); /* Slightly lighter maroon on hover */
}

.login-submit-button[disabled] {
  background-color: #ccc; /* Grey background when disabled */
  cursor: not-allowed; /* Cursor style when disabled */
}

.login-submit-button[disabled]:hover {
  background-color: #ccc; /* Grey background on hover when disabled */
}

.login-signup-link-container {
  width: 100%;
  margin-top: 10px;
  font-size: 1rem; /* Slightly larger font size for readability */
  text-align: left; /* Center the text */
}

.login-signup-link {
  color: var(--primary-main); /* Maroon color for signup link */
  text-decoration: none;
}

.login-signup-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.strikethrough-line {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0; /* Adjust the spacing around the line */
  width: 100%; /* Ensures the component takes up full width */
}

.strikethrough-line::before,
.strikethrough-line::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000; /* Line color */
  margin: 0 10px; /* Space between line and text */
}

.strikethrough-line span {
  padding: 0 10px; /* Space around the text */
  font-weight: bold; /* Bold text */
  color: var(--gray-5); /* Text color */
  font-size: 16px; /* Adjust font size as needed */
}

.google-login {
  width: 100%;
  display: flex;
  justify-content: center;
}

.google-login-button {
  width: 100%;
  padding: 12px;
  gap: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #3831ff; /* Google red color */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-login button:hover {
  background-color: #5f5aff; /* Darker red on hover */
}

.google-icon {
  margin-right: 10px;
}

.login-error-message {
  color: red; /* Red text color for error message */
  font-size: 1rem; /* Adjust the font size as needed */
  margin-top: 20px; /* Adjust the space above the error message */
  text-align: center; /* Center the error message */
}

@media (max-width: 768px) {
  .login-form {
    max-width: 350px;
  }
}
