.phn-page-container {
  width: 100%;
  height: 100vh;
}

.phn-page-container h1 {
  color: var(--gray-5);
  text-align: left;
  height: 70px;
  padding: 20px;
}

.phn-divide-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: calc(100% - 80px);
  padding: 20px;
  gap: 20px;
}

.phn-horizontal-container {
  display: flex;
  flex-direction: row;
  align-items: left;
  flex-wrap: nowrap;
  width: 100%;
  height: calc(100% - 80px);
  padding: 20px;
  gap: 10px;
}

.phn-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  flex-wrap: nowrap;
  width: 100%;
  height: calc(100% - 80px);
  padding: 20px;
  gap: 10px;
}

.phn-vertical-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.phn-horizontal-content {
  display: flex;
  flex-direction: row;
  align-items: left;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.phn-horizontal-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
}

.phn-vertical-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
}

@media (max-width: 768px) {
  .phn-page-container {
    height: auto;
  }

  .phn-page-container h1 {
    font-size: 1.6rem;
    height: 60px;
    padding: 15px;
  }

  .phn-divide-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
    gap: 10px;
  }

  .phn-horizontal-container {
    padding: 10px;
    height: calc(100vh - 120px);
    gap: 10px;
    overflow-y: none;
  }

  .phn-vertical-container {
    padding: 10px;
    height: calc(100vh - 120px);
    gap: 10px;
    overflow-y: none;
  }

  .phn-vertical-content {
    gap: 10px;
  }

  .phn-horizontal-content {
    gap: 10px;
  }
}
