input,
select {
  padding: 8px;
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
}

.phn-input {
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.phn-dropdown {
  margin: 0px;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 15px;
}
