/* src/components/PassageWithTranslation.css */

.passage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.passage-word {
  font-weight: normal;
}

.passage-word.large {
  font-size: 2rem;
}

.passage-word.small {
  font-size: 1.6rem;
}

.passage-phonetic.large {
  font-size: 0.9rem;
  font-weight: normal;
}

.passage-phonetic.small {
  font-size: 0.7rem;
  font-weight: normal;
}

.passage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .passage-word.large {
    font-size: 1.6rem;
  }

  .passage-word.small {
    font-size: 1.4rem;
  }

  .passage-phonetic.large {
    font-size: 0.7rem;
  }

  .passage-phonetic.small {
    font-size: 0.6rem;
  }
}
