.sidebar {
  width: 210px; /* Expanded width */
  background-color: var(--primary-main);
  border-right: 2px solid var(--primary-dark);
  color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  z-index: 1001;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.sidebar-menu {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.sidebar-logo-text {
  font-size: 1.6rem;
  font-weight: bold;
}

.sidebar-menu-icon {
  cursor: pointer;
  margin-left: auto;
  font-size: 2.4rem;
}

.sidebar-nav-icon {
  font-size: 2.4rem;
}

.sidebar-nav-links {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.sidebar-nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.sidebar-nav-link:hover,
.sidebar-nav-link.active {
  /* Highlight the current page */
  color: var(--secondary-main); /* Yellow color for active link */
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: auto; /* Pushes the button to the bottom */
  margin-bottom: 20px;
  gap: 20px;
}

.sidebar-streak-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  gap: 10px;
}

.sidebar-streak {
  display: flex;
  width: 38px;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
}

.sidebar-streak-icon {
  font-size: 1.8rem;
}

.sidebar-language-flag-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
}

.sidebar-language-flag-img {
  width: 40px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 8px;
  border: white solid 2px;
}

.sidebar-language-flags-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 120px; /* Adjust according to the sidebar height */
  left: 50px;
  height: 200px;
  width: 200px; /* Fixed width */
  background-color: #fff;
  color: #000;
  border-radius: 20px 20px 20px 0px;
  border: var(--gray-2) solid 2px;
  z-index: 1001; /* Ensure the popup is on top */
  overflow-y: auto; /* Enable vertical scrolling */
}

.sidebar-language-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid gray; /* Added gray line at the bottom */
}

.sidebar-language-choice:hover {
  background-color: var(--gray-0);
}

.sidebar-language-choice.selected {
  background-color: var(--highlight-main);
}

.sidebar-language-popup-flag-img {
  width: 40px;
  height: 30px;
  object-fit: cover;
  border-radius: 8px;
  border: var(--gray-5) solid 2px;
}

.sidebar-language-name {
  text-align: left;
  font-weight: bold;
  font-size: 0.9rem;
}

.sidebar-language-star-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.sidebar-language-star {
  font-size: 1rem;
  color: gold;
}

.sidebar-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  gap: 10px;
}

.sidebar-profile-popup {
  position: absolute;
  bottom: 55px; /* Adjust according to the sidebar height */
  left: 45px;
  width: 200px; /* Fixed width */
  background-color: #fff;
  color: #000;
  border-radius: 20px 20px 20px 0px;
  border: var(--gray-2) solid 2px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1001; /* Ensure the popup is on top */
}

.sidebar-popup-button {
  background-color: transparent;
  border: none;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-popup-button:hover {
  background-color: #f0f0f0;
}

.sidebar-logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-main); /* Tomato color */
  color: black;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  border: none;
  padding: 10px;
  margin-top: 10px;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.sidebar-logout-button:hover {
  background-color: var(--secondary-light); /* Darker tomato color */
}

@keyframes fadeInRiseUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutGoDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes fadeInGoDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutGoUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.sidebar-language-flags-popup,
.sidebar-profile-popup {
  animation: fadeInRiseUp 0.25s ease-in-out;
}

.sidebar-language-flags-popup.fade-out,
.sidebar-profile-popup.fade-out {
  animation: fadeOutGoDown 0.25s ease-in-out;
}

@media (max-width: 768px) {
  .sidebar,
  .sidebar.collapsed {
    flex-direction: row; /* Display items horizontally */
    width: 100%; /* Full width on mobile */
    height: 60px; /* Auto height to fit content */
    border: none;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    justify-content: space-between; /* Space items evenly */
  }

  .sidebar-header {
    flex-direction: row;
    gap: 0px;
  }

  .sidebar-nav-links {
    flex-direction: row; /* Stack links vertically */
    align-items: center; /* Center align links */
    width: 100%; /* Full width for links */
    padding: 0px;
    margin: 0px;
    gap: 15px;
  }

  .sidebar-nav-icon {
    font-size: 2rem; /* Smaller font size for mobile */
    justify-content: center; /* Align links to the left */
    flex-direction: row; /* Stack links vertically */
    align-items: center; /* Center align links */
  }

  .sidebar-bottom {
    margin: 0px;
    gap: 15px;
    flex-direction: row;
    align-items: center;
  }

  .sidebar-streak {
    margin-left: auto;
    flex-direction: row;
    gap: 3px;
    width: 100%;
    font-size: 1.1rem;
  }

  .sidebar-streak-icon {
    font-size: 1.4rem;
  }

  .sidebar-language-flag-img {
    margin: 0px;
  }

  .sidebar-language-flags-popup {
    bottom: auto;
    top: 60px;
    right: 10px;
    left: auto;
    width: 200px; /* Fixed width */
    height: 300px;
    z-index: 1001;
    border-radius: 10px 0px 10px 10px;
  }

  .sidebar-profile-popup {
    bottom: auto;
    top: 60px;
    right: 10px;
    left: auto;
    width: 180px; /* Fixed width */
    z-index: 1001;
    border-radius: 10px 0px 10px 10px;
  }

  .sidebar-language-flags-popup,
  .sidebar-profile-popup {
    animation: fadeInGoDown 0.25s ease-in-out;
  }

  .sidebar-language-flags-popup.fade-out,
  .sidebar-profile-popup.fade-out {
    animation: fadeOutGoUp 0.25s ease-in-out;
  }

  .sidebar-menu-icon {
    display: none; /* Hide menu icon on mobile */
  }
}
