.phn-white-card {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 25px;
}

.phn-gray-card {
  background-color: var(--gray-0);
  color: black;
  padding: 20px;
  border-radius: 15px;
}

/* Add these new styles at the end of the file */
@keyframes skeleton-loading {
  0% {
    background-color: rgba(0, 0, 0, 0.05);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.15);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.skeleton {
  background-color: rgba(0, 0, 0, 0.05);
  animation: skeleton-loading 1.8s ease-in-out infinite;
}

@media (max-width: 768px) {
  .phn-button,
  .phn-cancel-button,
  .phn-text-button {
    font-size: 1rem;
  }
}
