.cards-data-table-container {
  height: 63vh;
}

.cards-deck-select {
  min-width: 100px;
  max-width: 300px;
}

@media (max-width: 768px) {
  .cards-data-table-container {
    height: 62vh;
  }
  .card-action-button {
    font-size: 1rem;
  }
}
