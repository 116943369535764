.voice-dropdowns {
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  flex-shrink: 0;
}

.voice-dropdowns label {
  font-size: 1rem;
  font-weight: bold;
}

.speed-dropdown {
  width: 100px;
}
