.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  background-color: #f3f3f3;
}

.payment-title {
  font-size: 3rem;
  color: var(--primary-main);
  margin-bottom: 20px;
  text-align: center;
}

.payment-free-trial-text {
  font-size: 1.2rem;
  color: var(--primary-main);
  margin-bottom: 60px;
  text-align: center;
}

.payment-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.payment-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  width: 300px;
  height: 350px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-featured {
  border: 2px solid var(--primary-main);
  position: relative;
  z-index: 1;
  height: 400px;
}

.payment-featured:hover {
  border-color: var(--primary-light);
}

.payment-featured-label {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-main);
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.payment-featured:hover .payment-featured-label {
  background-color: var(--primary-light);
}

.payment-card h2 {
  font-size: 2rem;
  color: var(--primary-main);
}

.payment-price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-original-price {
  font-size: 1.2rem;
  color: var(--gray-5);
  text-decoration: line-through;
}

.payment-price {
  font-size: 3rem;
  font-weight: bold;
  color: var(--gray-5);
}

.payment-price-per-month {
  font-size: 1.2rem;
  color: var(--gray-5);
}

.payment-price-per-month span {
  font-size: 1rem;
  font-weight: normal;
}

.payment-savings {
  font-size: 1.2rem;
  color: green;
  margin-bottom: 20px;
}

.payment-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  border: none;
  background-color: var(--primary-main);
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-button:hover {
  background-color: var(--primary-light);
}

@media (max-width: 768px) {
  .payment-container {
    padding: 20px;
  }

  .payment-title {
    font-size: 2.2rem;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .payment-cards {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .payment-card {
    width: 300px;
    height: 320px;
  }

  .payment-featured {
    height: 370px;
  }

  .payment-free-trial-text {
    font-size: 1rem;
    margin-bottom: 40px;
  }
}
