/* src/css/ReviewPage.css */

.review-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.progress-bar {
  display: flex;
  text-align: center;
  font-weight: bold;
  width: 100%;
  background-color: #6a6a6a;
  color: white;
  height: 30px;
}

.uncomplete-progress,
.pass-progress,
.fail-progress {
  padding-top: 5px;
  text-align: center;
  height: 100%;
}

.pass-progress,
.fail-progress {
  width: 1%;
  transition: width 0.3s ease-in-out;
}

.pass-progress {
  background-color: #28a745;
}

.fail-progress {
  background-color: #dc3545;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 30px - 60px - 100px);
}

.card-display {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  min-width: 300px;
  padding: 40px;
}

.card-front,
.card-back {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.layout-row {
  margin-bottom: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.card-field {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  padding: 5px;
}

.review-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px;
}

.review-card-progress {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-main);
}

.button-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-weight: bold;
  width: 100%;
  background-color: #fff;
}

.show-answer-button,
.pass-button,
.fail-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100px;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.show-answer-button {
  width: 100%;
  background-color: var(--primary-main);
}

.show-answer-button:hover {
  background-color: var(--primary-light);
}

.pass-button,
.fail-button {
  width: 50%;
}

.pass-button {
  background-color: #28a745;
}

.pass-button:hover {
  background-color: #218838;
}

.pass-button:disabled {
  background-color: #6da57a;
}

.fail-button {
  background-color: #dc3545;
}

.fail-button:hover {
  background-color: #c82333;
}

.fail-button:disabled {
  background-color: #b68288;
}

.menu-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
}

.menu-dropdown {
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item span {
  font-size: 0.9rem;
  color: #333;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  color: var(--primary-main);
  padding: 5px;
}

.icon-button:hover {
  color: var(--primary-light);
}

.review-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 2rem;
  padding: 20px;
  margin-top: 20px;
  gap: 40px;
}

.review-stats {
  display: flex;
  gap: 40px;
}

.stat-group {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  min-width: 300px;
}

.stat-group h3 {
  margin-bottom: 15px;
  color: var(--primary-main);
  font-size: 1.5rem;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 1.3rem;
}

.stat-value {
  font-weight: bold;
}

.stat-value.success {
  color: #28a745;
}

.stat-value.error {
  color: #dc3545;
}

.review-actions {
  display: flex;
  gap: 20px;
}

.review-action-button {
  padding: 12px 30px;
  font-weight: bold;
  font-size: 1.6rem;
  color: #fff;
  border: none;
  background-color: var(--primary-main);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.review-action-button:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .review-page {
    height: calc(100vh - 60px);
  }

  .card-container {
    padding: 10px;
    height: calc(100vh - 60px - 30px - 60px - 100px);
  }

  .card-display {
    min-width: 200px;
  }

  .review-card-progress {
    font-size: 1.2rem;
  }

  .card-front,
  .card-back {
    font-size: 1.4rem;
  }

  .review-complete-container {
    font-size: 1.5rem;
    gap: 20px;
    padding: 40px;
    margin-top: 0px;
  }

  .review-stats {
    flex-direction: column;
    gap: 10px;
    margin: 10px;
    width: 100%;
  }

  .stat-group {
    min-width: unset;
    width: 100%;
    padding: 15px;
  }

  .stat-group h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .stat-item {
    font-size: 1rem;
    margin: 8px 0;
  }

  .review-action-button {
    width: 100%;
    padding: 15px;
    font-size: 1.1rem;
  }
}
