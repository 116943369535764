/* AddCardPage.css */
/* Input and button styles */
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 500px;
}

.phrase-input {
  margin: 0px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: calc(100% - 100px);
  max-width: 400px;
}

/* Translation list styles */
.translation-list {
  gap: 10px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  padding-right: 10px; /* Add padding for scrollbar */
}

/* Ensure the bottom controls stay visible */
.phn-horizontal-group {
  flex-shrink: 0; /* Prevents these elements from shrinking */
}

/* Optional: Add custom scrollbar styling */
.translation-list::-webkit-scrollbar {
  width: 8px;
}

.translation-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.translation-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.translation-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.translation-item {
  padding: 15px 20px;
  cursor: pointer;
  max-width: 500px;
  transition: transform 0.3s ease;
}

.translation-item.selected {
  background-color: var(--highlight-main);
}

.translation-item:hover {
  transform: translateY(-3px); /* Slight upward movement on hover */
}

.translation-details {
  flex-grow: 1;
}

.phonetic,
.formality {
  font-size: 0.9rem;
  margin-top: 2px;
}

.add-cards-button {
  width: auto;
}

@media (max-width: 768px) {
  .add-card-page {
    padding: 10px;
  }

  .add-card-page h1 {
    font-size: 1.5rem;
  }

  .add-cards-button {
    width: 100%;
  }
}

.phn-horizontal-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 500px;
}

/* Update deck selection group styling */
.cards-deck-select {
  flex: 1; /* Allow deck dropdown to take available space */
}

.manual-input-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: left;
  width: auto;
  margin: 0;
  text-decoration: underline;
  opacity: 0.8;
  white-space: nowrap; /* Prevent button text from wrapping */
  flex-shrink: 0; /* Prevent button from shrinking */
}

.manual-input-toggle:hover {
  opacity: 1;
}

.manual-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
  padding: 10px 0;
}

.manual-input-container .phn-input {
  width: 100%;
}

.manual-input-container .phn-button {
  align-self: flex-end;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .manual-input-container {
    padding: 10px;
  }

  .manual-input-container .phn-button {
    width: 100%;
    align-self: center;
  }
}
