.phn-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.phn-popup {
  max-width: 90%;
  text-align: center;
}

.phn-popup h2 {
  text-align: center;
  margin-bottom: 20px;
}

.phn-popup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.phn-popup-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Space items evenly */
  width: 100%;
  margin-top: 20px;
}
