:root {
  --background-color: #dfdfdf;
  --primary-main: #00967d;
  --primary-light: #00b596;
  --primary-dark: #00755d;
  --secondary-main: #ffd700;
  --secondary-light: #ffe760;
  --secondary-dark: #efcc01;
  --highlight-main: #69d4ec;
  --gray-0: #e3e3e3;
  --gray-1: #c0c0c0;
  --gray-2: #acacac;
  --gray-3: #858585;
  --gray-4: #575757;
  --gray-5: #353535;
}
