/* Play button styles */
.phn-play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  border: none;
  background-color: transparent;
  color: var(--primary-main);
  cursor: pointer;
  transition: all 0.3s ease;
}

.phn-play-button:hover {
  color: var(--primary-light);
}

.phn-play-button:disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Add opacity for disabled state */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-spin {
  animation: spin 3s linear infinite;
}

/* Add new play button specific styles */
.phn-play-button .icon {
  transition: transform 0.2s ease;
}

.phn-play-button:hover .icon {
  transform: scale(1.1);
}

.phn-play-button:active .icon {
  transform: scale(0.9);
}
