.stories-story-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.stories-story-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
}

.stories-story-item:hover {
  transform: translateY(-3px);
}

.stories-story-item.disabled {
  cursor: not-allowed;
  background-color: var(--gray-1);
}

.stories-story-item.error {
  cursor: not-allowed;
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
}

.stories-story-title.error {
  color: #dc3545;
}

.stories-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust as needed */
}

.stories-story-title {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5px;
}

.stories-story-title-main {
  font-weight: bold;
  font-size: 1.6rem;
}

.stories-story-title-sub {
  font-size: 0.9rem;
}

.stories-item-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.stories-story-name {
  font-size: 1.2rem;
  margin: 0;
}

.stories-delete-confirmation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.stories-confirmation-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.stories-confirmation-content h2 {
  margin-top: 0;
}

.stories-confirmation-content p {
  margin: 15px 0;
}

.stories-confirm-button,
.stories-cancel-button {
  background-color: var(--primary-main);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.stories-confirm-button:hover,
.stories-cancel-button:hover {
  background-color: var(--primary-light);
}

.stories-cancel-button {
  background-color: #555555;
}

.stories-cancel-button:hover {
  background-color: #777777;
}

.stories-status-and-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.stories-item-buttons .error-trash {
  color: #dc3545;
}

@media (max-width: 768px) {
  .stories-story-title-main {
    font-size: 1.2rem;
  }

  .stories-story-title-sub {
    font-size: 0.8rem;
  }
}
