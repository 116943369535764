.phn-button,
.phn-cancel-button,
.phn-text-button,
.phn-button-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: var(--primary-main);
  color: white;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-wrap: nowrap;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.phn-button:hover,
.phn-button-small:hover {
  background-color: var(--primary-light);
}

.phn-button:disabled,
.phn-button-small:disabled {
  background-color: var(--gray-1);
  cursor: not-allowed;
}

.phn-button-small {
  padding: 5px 15px;
  font-size: 1rem;
}

.phn-text-button {
  background-color: transparent;
  color: var(--primary-main);
  font-size: 1.5rem;
}

.phn-text-button:hover {
  color: var(--primary-light);
}

.phn-text-button:disabled {
  color: var(--gray-1);
  cursor: not-allowed;
}

.phn-cancel-button {
  background-color: transparent;
  color: var(--gray-5);
}

.phn-cancel-button:hover {
  background-color: var(--gray-0);
}

.phn-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-main);
  font-size: 1.5rem;
  line-height: 0;
}

.phn-icon-button:hover {
  color: var(--primary-light);
}

.phn-button-tooltip {
  position: relative;
}

.phn-button-tooltip::before {
  content: attr(tooltip-data);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 10px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  font-size: 0.7rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
}

.phn-button-tooltip:hover::before {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .phn-button-tooltip:hover::before {
    opacity: 0;
    visibility: hidden;
  }
}
