.language-select-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.language-select-container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: black;
  padding: 40px;
  width: 350px;
  height: 600px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.language-selection {
  margin-top: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
}

.language-select-language-choice,
.language-select-language-choice-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid gray; /* Added gray line at the bottom */
}

.language-select-language-choice-selected {
  background-color: var(--highlight-main);
}

.language-select-language-choice:hover {
  background-color: var(--gray-1);
}

.language-select-language-flag-container {
  display: flex;
  height: 50px;
  align-items: center;
  margin-bottom: 30px; /* Pushes the button to the bottom */
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
}

.language-select-language-flag-img {
  width: 40px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
  border: var(--gray-5) solid 2px;
}

.language-select-language-name {
  text-align: left;
  font-weight: bold;
}

.language-select-sumbit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.4rem;
  transition: background-color 0.3s ease;
  background-color: var(--primary-main);
  color: white;
}

.language-select-sumbit-button:hover {
  background-color: var(--primary-light);
}

.language-select-sumbit-button[disabled] {
  background-color: #ccc; /* Grey background when disabled */
  cursor: not-allowed; /* Cursor style when disabled */
}

.language-select-cancel-button {
  background-color: var(--gray-2);
}
