.translation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.edit-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 40px;
  height: 30px;
}

.edit-icon {
  font-size: 1.6rem;
  visibility: hidden;
  cursor: pointer;
  line-height: 0;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: var(--primary-main);
}

.translation-container:hover .edit-icon {
  visibility: visible;
}

.translation {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  display: inline; /* Ensures the span only takes up as much space as needed */
}

.translation-edit {
  display: flex; /* Allows the input to only take up as much space as needed */
  width: 100%;
}

.translation-edit input {
  font-weight: bold;
  margin: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .edit-icon {
    visibility: visible;
  }
}
