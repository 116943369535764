.read-story-container {
  padding: 40px;
  height: 100vh;
}

.read-story-container h1 {
  margin-bottom: 5px;
}

.read-story-content-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
  height: 60vh;
}

.story-section,
.translation-section {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-radius: 20px;
  background-color: white;
  border: 3px solid var(--gray-1);
  padding: 20px;
  width: 100%;
  height: 100%;
  gap: 30px;
}

.story-section.small,
.translation-section.small {
  width: 50%;
  height: 100%;
}

.story-line,
.translation-line {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 20px;
  background-color: transparent;
  cursor: pointer;
}

.story-line.selected,
.translation-line.selected {
  background-color: #90cdff;
  border-radius: 10px;
}

.translation-section {
  width: 50%;
}

.translation-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  font-size: 1.4rem;
  margin-left: 10px;
}

.toggle-button-container {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  gap: 10px;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  line-height: 0;
}

.toggle-button:hover {
  color: var(--primary-main);
}

.audio-player {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.audio-toggle-button {
  background-color: transparent;
  color: var(--primary-main);
  font-size: 2.5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.audio-toggle-button:hover {
  color: var(--primary-light);
}

.audio-progress {
  margin: 0px;
  padding: 0px;
  accent-color: var(--primary-main);
  background: var(--gray-1);
}

.audio-time {
  font-size: 14px;
  color: #333;
  min-width: 100px;
  text-align: right;
}

@media (max-width: 768px) {
  .read-story-container {
    padding: 10px;
    height: calc(100vh - 60px);
  }

  .read-story-container h1 {
    font-size: 1.7rem;
  }
  .read-story-container h2 {
    font-size: 1.4rem;
  }

  .read-story-content-container {
    flex-direction: column;
    height: 75%;
  }

  .story-section,
  .translation-section {
    width: 100%;
    height: 100%;
  }

  .story-section.small,
  .translation-section.small {
    width: 100%;
    height: 50%;
  }

  .toggle-button-container {
    flex-direction: row;
  }
  .audio-player {
    margin-top: 10px;
  }
}
