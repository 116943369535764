/* index.css */

/* Apply a CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Prevent horizontal overflow and unwanted scroll behavior */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%; /* Use max-width to handle scrollbars */
  overflow-x: hidden; /* Prevent horizontal overflow */
  height: 100vh; /* Ensure full height */
  overscroll-behavior: none; /* Prevent scroll chaining and bounce effects */
  background-color: var(--background-color);

  /*DEBUGGING*/
  /*border: 2px solid blue; /* Highlight the body and html */
  /*overflow-x: hidden; /* Ensure no horizontal overflow */
}
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Apply a global font style and baseline styles */
body,
button,
select,
input {
  font-family: 'Poppins', 'ui-sans-serif', system-ui, sans-serif;
}

/* div {
  border: 1px solid blue;
} */

h1,
h2,
h3,
h4,
span {
  color: var(--gray-5);
  /*border: 1px solid blue; */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */
  border-top: 4px solid #007bff; /* Blue border */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import url('./styles/colors.css');
@import url('./styles/pages.css');
@import url('./styles/cards.css');
@import url('./styles/buttons.css');
@import url('./styles/popups.css');
@import url('./styles/inputs.css');
/* Disable blue highlight on tap for mobile devices */
@media (max-width: 768px) {
  input,
  textarea,
  button,
  select,
  div,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
  }
}

/* Disable blue outline for all devices */
* {
  outline: none;
}

*:focus {
  outline: none;
}
