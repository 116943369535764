.change-password-password-strength {
  font-size: 0.8rem;
  color: red; /* Maroon color for warning */
}

.change-password-password-confirm {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.change-password-password-match {
  position: absolute;
  top: 40%;
  right: 10px;
}

.change-password-green-check {
  color: green;
}

.change-password-red-cross {
  color: red;
}

.change-password-error-message {
  color: red; /* Red text color for error message */
  font-size: 1rem; /* Adjust the font size as needed */
  margin-top: 5px; /* Space above the error message */
  text-align: center; /* Center the error message */
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .change-password-password-strength {
    font-size: 0.8rem;
  }
}
