.dashboard-content {
  width: 50%;
}

.dashboard-todays-review,
.dashboard-keep-reading {
  width: 100%;
  height: calc(50% - 10px);
}

.dashboard-review-squares {
  display: flex;
  flex-direction: row;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical overflow */
  white-space: nowrap; /* Prevent line breaks inside the container */
  gap: 15px; /* Space between items */
  margin-top: 10px;
  padding: 10px;
  height: 80%;
}

.dashboard-review-square {
  height: 100%; /* Full height of the container */
  aspect-ratio: 1 / 1; /* Maintain a square shape */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: bold;
  gap: 10px;
  color: var(--gray-5); /* Dark grey text color for the name */
  transition: transform 0.2s ease-in-out;
}

.dashboard-review-square:hover {
  transform: translateY(-3px); /* Slight upward movement on hover */
}

.dashboard-review-name {
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  height: 80px;
  overflow: hidden; /* Hides overflow text */
  white-space: pre-wrap; /* Prevents text from wrapping */
}

.dashboard-review-name.long-name {
  font-size: 1.2rem;
}

.dashboard-review-details {
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: 20px;
  line-height: 1;
  font-size: 2.6rem;
}

.dashboard-review-details span {
  display: block;
  font-weight: bold; /* Normal weight for card numbers */
}

.dashboard-new-cards {
  color: #28a745; /* Bootstrap green */
}

.dashboard-review-cards {
  color: #007bff; /* Bootstrap blue */
}

.dashboard-learning-cards {
  color: #dc6a35; /* Bootstrap red */
}

.dashboard-review-complete,
.dashboard-no-stories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 90%;
  color: gray;
}

.dashboard-review-complete h2 {
  font-size: 2.5rem;
}

.dashboard-stories-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  margin-top: 10px;
  padding: 10px;
  gap: 10px;
  overflow: scroll;
}

.dashboard-story-item {
  padding: 15px 20px;
  cursor: pointer;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.dashboard-story-item:hover {
  transform: translateY(-3px);
}

.dashboard-story-title {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5px;
}

.dashboard-story-title-main {
  font-weight: bold;
  font-size: 1.4rem;
}

.dashboard-story-title-sub {
  font-size: 0.8rem;
}

.dashboard-no-stories h2 {
  font-size: 2.5rem;
}

.dashboard-stats {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

.dashboard-vocab-size-stats,
.dashboard-cards-reviewed-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  height: calc(50% - 10px);
}

@media (max-width: 768px) {
  .dashboard-content {
    width: 100%;
  }

  .dashboard-todays-review,
  .dashboard-keep-reading {
    height: 250px;
  }

  .dashboard-todays-review h2,
  .dashboard-keep-reading h2,
  .dashboard-stats h2 {
    font-size: 1.2rem;
  }

  .dashboard-review-complete h2,
  .dashboard-no-stories h2 {
    font-size: 1.8rem;
  }

  .dashboard-review-language,
  .dashboard-review-squares {
    margin-top: 10px;
  }

  .dashboard-review-name {
    margin-top: 0px;
    font-size: 1.4rem; /* Adjusted font size for name */
    margin-bottom: 0px;
  }

  .dashboard-review-name.long-name {
    font-size: 1rem;
  }

  .dashboard-review-details {
    margin-top: 0px;
    gap: 15px; /* Adjusted gap between details */
    font-size: 2rem; /* Adjusted font size for details */
  }

  .dashboard-story-title-main {
    font-size: 1rem;
  }

  .dashboard-story-title-sub {
    font-size: 0.7rem;
  }

  .dashboard-stats {
    height: 500px;
  }
}

.floating-action-menu {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.phn-page-container .fab-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--primary-main) !important;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fab-icon {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.fab-icon.rotate {
  transform: rotate(45deg);
}

.fab-menu {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.fab-menu-item {
  padding: 0.75rem 1rem;
  border-radius: 25px;
  background-color: white;
  border: 1px solid #ddd;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.fab-menu-item:hover {
  background-color: #f8f9fa;
}
