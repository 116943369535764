/* src/pages/SignupPage.css */

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.signup-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.signup-title {
  font-size: 1.8rem;
  font-weight: normal;
  color: var(--primary-main);
  margin-bottom: 20px;
  text-align: center;
}

.signup-input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.signup-input-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.signup-password-strength {
  font-size: 0.7rem;
  text-align: center;
  color: red;
  margin-top: 5px;
}

.signup-password-confirm {
  position: relative;
  width: 100%;
}

.signup-password-match {
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
}

.signup-green-check {
  color: green;
}

.signup-red-cross {
  color: red;
}

.signup-terms {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  width: 100%;
}

.signup-terms input[type='checkbox'] {
  width: auto;
  margin-right: 10px;
  margin-top: 3px;
}

.signup-terms label {
  flex-direction: row;
  font-size: 0.9rem;
  line-height: 1.4;
  gap: 5px;
  flex: 1;
}

.signup-terms a {
  color: var(--primary-main);
  text-decoration: none;
}

.signup-terms a:hover {
  text-decoration: underline;
}

.signup-submit-button {
  width: 100%;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: var(--primary-main);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-submit-button:hover {
  background-color: var(--primary-light);
}

.signup-submit-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.signup-error-message {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

.signup-login-link-container {
  width: 100%;
  margin-top: 20px;
  font-size: 1rem;
  text-align: center;
}

.signup-login-link {
  color: var(--primary-main);
  text-decoration: none;
}

.signup-login-link:hover {
  text-decoration: underline;
}

.signup-success {
  text-align: center;
  padding: 20px;
  background-color: #e6ffe6;
  border-radius: 10px;
}

.signup-success h2 {
  color: #4caf50;
  margin-bottom: 15px;
}

.signup-continue-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-continue-button:hover {
  background-color: #45a049;
}

.signup-strikethrough-line {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  width: 100%;
}

.signup-strikethrough-line::before,
.signup-strikethrough-line::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
  margin: 0 10px;
}

.signup-strikethrough-line span {
  padding: 0 10px;
  font-weight: bold;
  color: var(--gray-5);
  font-size: 16px;
}

.signup-google {
  width: 100%;
  display: flex;
  justify-content: center;
}

.signup-google-button {
  width: 100%;
  padding: 12px;
  gap: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #3831ff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-google-button:hover {
  background-color: #5f5aff;
}

.signup-google-icon {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .signup-form {
    max-width: 350px;
  }
}
