/* src/css/MainLayout.css */

.main-content {
  margin-left: 210px;
  width: calc(100% - 210px);
  height: 100%;
}

.main-content.sidebar-collapsed {
  margin-left: 60px;
  width: calc(100% - 60px);
}

@media (max-width: 768px) {
  .main-content,
  .main-content.sidebar-collapsed {
    margin-top: 60px;
    margin-left: 0px;
    width: 100%;
    height: auto;
  }

  .custom-toast {
    width: 90% !important; /* Make the toast take up most of the screen width on mobile */
    margin: 0 auto !important; /* Center the toast */
    border-radius: 30px; /* Optional: rounded corners */
  }

  .custom-toast-body {
    font-size: 1rem; /* Adjust the font size for better readability on mobile */
    text-align: center; /* Center align the text */
  }

  .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

/* Slide-in-right transition */
@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-in-right {
  animation: slide-in-right 0.3s forwards;
}

.slide-out-right {
  animation: slide-out-right 0.3s forwards;
}

/* Slide-in-bottom transition */
@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-bottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.slide-in-bottom {
  animation: slide-in-bottom 0.3s forwards;
}

.slide-out-bottom {
  animation: slide-out-bottom 0.3s forwards;
}
