.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  gap: 7px;
  position: fixed;
  transform: translate(-50%, -100%);
  padding: 10px;
  background-color: var(--gray-0);
  color: black;
  border: 2px solid;
  border-color: var(--gray-4);
  border-radius: 15px;
  z-index: 1000;
}

.tooltip-words,
.tooltip-save {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.tooltip-save:hover {
  transform: scale(1.2);
}

.tooltip-save {
  color: white;
  background-color: transparent;
}

.tooltip-save-icon {
  font-size: 1.4rem;
  margin-bottom: 3px;
}

.tooltip-save-name {
  font-size: 0.8rem;
}

.tooltip-word {
  font-size: 1.4rem;
  font-weight: normal;
}

.tooltip-phonetic {
  font-size: 0.9rem;
  font-weight: normal;
}

.tooltip-translation {
  font-size: 1.2rem;
  font-weight: normal;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tooltip-content-word {
  padding: 2px;
  font-weight: normal;
}

.tooltip-content-word.highlight {
  background-color: var(--highlight-main);
  border-radius: 5px;
}

.tooltip-content-phonetic.large {
  font-size: 0.9rem;
  font-weight: normal;
}

.tooltip-content-phonetic.small {
  font-size: 0.7rem;
  font-weight: normal;
}

.tooltip-content-word.large {
  font-size: 2rem;
}

.tooltip-content-word.small {
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .tooltip-content-phonetic.large {
    font-size: 0.7rem;
  }

  .tooltip-content-phonetic.small {
    font-size: 0.6rem;
  }

  .tooltip-content-word.large {
    font-size: 1.6rem;
  }

  .tooltip-content-word.small {
    font-size: 1.4rem;
  }
}
